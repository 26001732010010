<template>
  <q-page class="flex flex-center">
    <!-- <img alt="Quasar logo" src="../assets/logo.svg" style="width: 200px; height: 200px"> -->
    Need to connect : 
    <a :href="esiea_auth_endpoint + '/login?redirect_url=' + current_url">Connect</a>
  </q-page>
</template>

<style>
</style>

<script>
export default {
  name: 'Authentification',
  setup () {
    return {
      esiea_auth_endpoint: process.env.VUE_APP_ESIEA_AUTH_ENDPOINT,
      current_url: window.location.href
    }
  }
}
</script>
